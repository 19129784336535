/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.tv-lightweight-charts {
    @apply rounded-md;
}

.table-non-sticky thead th:first-child {
    position: relative;
}
